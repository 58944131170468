import LayoutA3 from "../../components/layout-a3"
import React from "react"
import "../themeA1/enroll-info-list.css"
import EnrollRacesList from '../../components/enroll-races-list'


export default class A3EnrollInfoList extends React.Component {

  render() {
    const color=this.props.pageContext.element.color
    const src="/demos/themeA3/profile/enrollInfo"

    return(
      <LayoutA3 >
        <EnrollRacesList color={color} src={src} />
      </LayoutA3>
    )
  }
}